import React, { useContext } from "react";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { AppContext } from "../../App";

export default function ToggleToolbar() {
  const { toolbarChecked, setToolbarChecked } = useContext(AppContext);

  const handleChange = (event) => {
    setToolbarChecked(event.target.checked);
  };

  return (
    <FormGroup className="Toolbar">
      <FormControlLabel
        control={
          <Switch
            checked={toolbarChecked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Toolbar"
      />
    </FormGroup>
  );
}
