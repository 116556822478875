import React from "react";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const SelectAssetType = ({ createType, handleAssetTypeChange }) => {
  return (
    <div>
      <FormControl sx={{ m: 3, width: 250 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={createType}
          onChange={handleAssetTypeChange}
        >
          <MenuItem value={"Computer"}>New Computer Asset</MenuItem>
          <MenuItem value={"Mobile"}>New Mobile Asset</MenuItem>
          <MenuItem value={"Equipment"}>New Equipment Asset</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectAssetType;
