import { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import SelectAssetType from "./SelectAssetType";

//Firebase & Firestore
import { collection, addDoc } from "firebase/firestore";
import { useFirestore } from "reactfire";
// UUID
import { v4 as uuidv4 } from "uuid";

//React Context
import { AppContext } from "../../../App";

function CreateEquipmentAsset() {
  const { isLoggedIn } = useContext(AppContext);

  const [geshdoId, setGeshdoId] = useState("");
  const [purchaseDate, setPurchaceDate] = useState("");
  const [type, setType] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [model, setModel] = useState("");
  const [serialnr, setSerialNr] = useState("");
  const [lentTo, setLentTo] = useState("");
  const [lentDate, setLentDate] = useState("");
  const [moreInfo, setMoreInfo] = useState("");

  const [createType, setCreateType] = useState("Equipment");
  const [comment, setComment] = useState("");

  const firestore = useFirestore();
  const assetCollection = collection(firestore, "equipment");

  const handleGeshdoIdChange = (e) => {
    setGeshdoId(e.target.value);
  };

  const handlePurchaseDateChange = (e) => {
    setPurchaceDate(e.target.value);
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleManufacturerChange = (e) => {
    setManufacturer(e.target.value);
  };

  const handleModelChange = (e) => {
    setModel(e.target.value);
  };

  const handleSerialNumberChange = (e) => {
    setSerialNr(e.target.value);
  };

  const handleLentToChange = (e) => {
    setLentTo(e.target.value);
  };

  const handleLentDateChange = (e) => {
    setLentDate(e.target.value);
  };

  const handleMoreInfoChange = (e) => {
    setMoreInfo(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleAssetTypeChange = (e) => {
    if (e.target.value === "Computer") {
      setCreateType("Computer");
    }
    if (e.target.value === "Mobile") {
      setCreateType("Mobile");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await addDoc(assetCollection, {
      geshdoId,
      purchaseDate,
      type,
      manufacturer,
      model,
      serialnr,
      lentTo,
      lentDate,
      moreInfo,
      comment,
      id: uuidv4(),
    });
    setGeshdoId("");
    setPurchaceDate("");
    setType("");
    setManufacturer("");
    setModel("");
    setSerialNr("");
    setLentTo("");
    setLentDate("");
    setMoreInfo("");
    setComment("");
  };

  if (!isLoggedIn) return <Redirect to="/signin" />;
  if (createType === "Computer") return <Redirect to="/create" />;
  if (createType === "Mobile") return <Redirect to="/createMobile" />;

  return (
    <>
      <div className="AssetHeader">
        <h3 className="CreateLink">Create New Equipment Asset</h3>
      </div>

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 3, width: "20rem" },
        }}
        noValidate
        autoComplete="off"
      >
        <SelectAssetType
          createType={createType}
          handleAssetTypeChange={handleAssetTypeChange}
        />

        <div>
          <TextField
            id="date"
            label="Date of purchase"
            type="date"
            defaultValue={purchaseDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handlePurchaseDateChange}
          />
        </div>
        <div>
          <TextField
            id="outlined-required"
            label="Geshdo ID"
            value={geshdoId}
            onChange={handleGeshdoIdChange}
            variant="filled"
          />
          <TextField
            id="outlined-required"
            label="Manufacturer"
            value={manufacturer}
            onChange={handleManufacturerChange}
            variant="filled"
          />
          <TextField
            id="outlined-required"
            label="Model"
            value={model}
            onChange={handleModelChange}
            variant="filled"
          />
        </div>
        <div>
          <TextField
            id="outlined-required"
            label="Serial number"
            value={serialnr}
            onChange={handleSerialNumberChange}
            variant="filled"
          />
          <TextField
            id="outlined-required"
            label="Type"
            value={type}
            onChange={handleTypeChange}
            variant="filled"
          />
        </div>

        <div>
          <TextField
            id="outlined-required"
            label="Lent To"
            value={lentTo}
            onChange={handleLentToChange}
            variant="filled"
          />
          <TextField
            id="outlined-required"
            label="Lent date"
            type="date"
            defaultValue={lentDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleLentDateChange}
          />
        </div>

        <div>
          <TextField
            id="outlined-multiline-static"
            label="More Info"
            multiline
            rows={4}
            value={moreInfo}
            onChange={handleMoreInfoChange}
            variant="filled"
          />
          <TextField
            id="outlined-multiline-static"
            label="Comments"
            multiline
            rows={4}
            value={comment}
            onChange={handleCommentChange}
            variant="filled"
          />
        </div>
        <Stack spacing={2} direction="row" padding={3}>
          <Button variant="contained" onClick={handleSubmit}>
            Create
          </Button>
        </Stack>
        <div></div>
      </Box>
    </>
  );
}

export default CreateEquipmentAsset;
