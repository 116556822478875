// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, OAuthProvider } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export const firebaseConfig = {
  apiKey: "AIzaSyD0nv1h89xOQ85TDiKE5Aib4pNtNcutQnQ",
  authDomain: "geshdo-assassin-dev.firebaseapp.com",
  projectId: "geshdo-assassin-dev",
  storageBucket: "geshdo-assassin-dev.appspot.com",
  messagingSenderId: "447253716779",
  appId: "1:447253716779:web:b980866d03d0b66a31af6e",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize auth && firestore with the 'firebaseApp' property
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);

// Initialize Provider & Export
export const microsoftProvider = new OAuthProvider(
  "microsoft.com"
).setCustomParameters({
  tenant: "cd20e4c9-f82c-4d3e-9224-90f2bc4be1a0", // Put Tenant Id from Azure registered app,
});

export default firebaseApp;
