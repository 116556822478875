import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Avatar } from "@mui/material";
import Button from "@mui/material/Button";
import { pink } from "@mui/material/colors";

// Firebase Auth
import { auth } from "../../config/fbConfig";
import { signOut } from "firebase/auth";

//React Context
import { AppContext } from "../../App";

const SignedInLinks = () => {
  const { userInitials } = useContext(AppContext);

  const logOutHandler = async () => {
    await signOut(auth)
      .then(() => {
        console.log("User signed out");
      })
      .catch((error) => {
        console.error("There was an error signing out");
      });
  };
  return (
    <>
      <ul>
        <li>
          <NavLink to="/create" className="NavLink">
            <Button color="inherit">New Asset</Button>
          </NavLink>

          <Button color="inherit" onClick={logOutHandler}>
            Log Out
          </Button>
        </li>
      </ul>
      <Avatar sx={{ bgcolor: pink[500] }}>{userInitials}</Avatar>
    </>
  );
};

export default SignedInLinks;
