import React, { useState, useContext } from "react";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";

import "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import {
  collection,
  query,
  updateDoc,
  where,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";

//React Context
import { AppContext } from "../../../App";

function EquipmentList() {
  const { isLoggedIn, setBackDrop, deleteItem, setDeleteItem, toolbarChecked } =
    useContext(AppContext);
  const [ref, setRef] = useState("");

  const editMode = isLoggedIn ? true : false;

  const columns = [
    //{ field: "id", headerName: "ID", width: 100 },
    {
      field: "geshdoId",
      headerName: "Geshdo ID",
      width: 100,
      editable: editMode,
    },
    {
      field: "serialnr",
      headerName: "Serial",
      width: 140,
      editable: editMode,
    },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      width: 140,
      editable: editMode,
    },
    {
      field: "model",
      headerName: "Model",
      width: 180,
      editable: editMode,
    },
    {
      field: "type",
      headerName: "Type",
      width: 140,
      editable: editMode,
    },
    {
      field: "purchaseDate",
      headerName: "Purchase Date",
      width: 130,
      editable: editMode,
    },

    {
      field: "lentTo",
      headerName: "Lent To",
      width: 150,
      editable: editMode,
    },
    {
      field: "lentDate",
      headerName: "Lent Date",
      width: 150,
      editable: editMode,
    },

    {
      field: "moreInfo",
      headerName: "More Info",
      width: 400,
      editable: editMode,
    },

    {
      field: "comment",
      headerName: "Comment",
      width: 450,
      editable: editMode,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => deleteAsset(id)}
        />,
      ],
    },
  ];

  // easily access the Firestore library
  const firestore = useFirestore();
  const assetCollection = collection(firestore, "equipment");
  const assetQuery = query(assetCollection);

  const { status, data: equipment } = useFirestoreCollectionData(assetQuery);

  if (status === "loading") {
    return <p>Loading</p>;
  }

  const allAssetData = (equipment) => {
    const data = equipment.map((eq) => {
      return {
        id: eq.id,
        geshdoId: eq.geshdoId,
        serialnr: eq.serialnr,
        model: eq.model,
        type: eq.type,
        manufacturer: eq.manufacturer,
        purchaseDate: eq.purchaseDate,
        lentTo: eq.lentTo,
        lentDate: eq.lentDate,
        moreInfo: eq.moreInfo,
        comment: eq.comment,
      };
    });
    return data;
  };

  const dataToList = allAssetData(equipment);

  let tempId = "";

  const deleteAsset = (id) => {
    if (tempId === "") {
      rowHandler(id);
    }
    console.log(tempId);
    setBackDrop(true);
  };

  const handleDelete = async () => {
    await deleteDoc(ref);

    setDeleteItem(false);
  };

  const cellHandler = async (params) => {
    let fieldName = params[0];
    let fieldValue = params[1];

    // if (fieldValue === "delete item") {
    //   setBackDrop(true);
    // }

    await updateDoc(ref, { [fieldName]: fieldValue });
  };

  if (deleteItem) {
    handleDelete();
    console.log("item deleted");
  }

  const rowHandler = async (row) => {
    const q = query(collection(firestore, "equipment"), where("id", "==", row));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      tempId = doc.id;
    });

    // debug logs
    console.log(tempId);

    const documentRef = doc(firestore, "equipment", tempId);
    setRef(documentRef);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        rows={dataToList}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection={false}
        disableSelectionOnClick={false}
        onRowClick={(row) => rowHandler(row.id)}
        onCellEditCommit={(params) => cellHandler([params.field, params.value])}
        components={
          toolbarChecked
            ? {
                Toolbar: GridToolbar,
              }
            : null
        }
      />
    </div>
  );
}

export default EquipmentList;
