import React, { createContext, useState, useMemo } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Dashboard from "./components/dashboard/Dashboard";

// SSO SignIn
import GeshdoSignIn from "./components/auth/GeshdoSignIn";

import CreateAsset from "./components/assetPages/create/CreateAsset";
import CreateMobileAsset from "./components/assetPages/create/CreateMobileAsset";
import CreateEquipmentAsset from "./components/assetPages/create/CreateEquipmentAsset";

import ComputerList from "./components/assetPages/list/ComputerList";
import MobileList from "./components/assetPages/list/MobileList";
import EquipmentList from "./components/assetPages/list/EquipmentList";

import { getFirestore } from "firebase/firestore";
import { useFirebaseApp, FirestoreProvider } from "reactfire";
import "./global.css";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./config/fbConfig";

//React Context
export const AppContext = createContext();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [userInitials, setUserInitials] = useState("");
  const [backDrop, setBackDrop] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [toolbarChecked, setToolbarChecked] = useState(false);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in
      setIsLoggedIn(true);
      // Get user info
      const email = user.email;
      const nameSplit = email.split(".");

      const initials = (nameSplit[0][0] + nameSplit[1][0]).toUpperCase();
      setUserInitials(initials);
    } else {
      // No user signed in
      setIsLoggedIn(false);
    }
  });

  const value = useMemo(
    () => ({
      isLoggedIn,
      authError,
      setAuthError,
      userInitials,
      backDrop,
      setBackDrop,
      deleteItem,
      setDeleteItem,
      toolbarChecked,
      setToolbarChecked,
    }),
    [isLoggedIn, authError, userInitials, backDrop, deleteItem, toolbarChecked]
  );
  const firestoreInstance = getFirestore(useFirebaseApp());

  return (
    <BrowserRouter>
      <div className="App">
        <FirestoreProvider sdk={firestoreInstance}>
          <AppContext.Provider value={value}>
            <Navbar />
            <Switch>
              {!isLoggedIn ? (
                <GeshdoSignIn />
              ) : (
                <>
                  <Route exact path="/">
                    <Dashboard list={<ComputerList />} />
                  </Route>
                  <Route path="/mobileList">
                    <Dashboard list={<MobileList />} />
                  </Route>
                  <Route path="/equipmentList">
                    <Dashboard list={<EquipmentList />} />
                  </Route>
                  <Route path="/signin">
                    <GeshdoSignIn />
                  </Route>

                  <Route path="/create">
                    <CreateAsset />
                  </Route>
                  <Route path="/createMobile">
                    <CreateMobileAsset />
                  </Route>
                  <Route path="/createEquipment">
                    <CreateEquipmentAsset />
                  </Route>
                </>
              )}
            </Switch>
          </AppContext.Provider>
        </FirestoreProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
