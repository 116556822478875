import React, { useContext } from "react";
import Backdrop from "@mui/material/Backdrop";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

//React Context
import { AppContext } from "../../App";

export default function SimpleBackdrop() {
  const { backDrop, setBackDrop, setDeleteItem } = useContext(AppContext);

  const handleClose = () => {
    setBackDrop(false);
  };

  const handleDeleteItem = () => {
    setDeleteItem(true);
    setBackDrop(false);
  };

  function ActionAlerts() {
    return (
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert
          variant="filled"
          severity="warning"
          action={
            <>
              <Button onClick={handleClose} color="inherit" size="small">
                CANCEL
              </Button>
              <Button onClick={handleDeleteItem} color="inherit" size="small">
                OK
              </Button>
            </>
          }
        >
          Are you sure you want to delete this item?
        </Alert>
      </Stack>
    );
  }

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDrop}
      >
        <div>
          <ActionAlerts />
        </div>
      </Backdrop>
    </div>
  );
}
