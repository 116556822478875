import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import SimpleBackdrop from "./SimpleBackdrop";
import ToggleToolbar from "./ToggleToolbar";

function Dashboard({ list }) {
  return (
    <>
      <div>
        <SimpleBackdrop />
        <ToggleToolbar />
      </div>
      <div>
        <CssBaseline />
        <Container maxWidth="false" className="DashBoard">
          <Box sx={{ bgcolor: "#F6F6F6", height: "85vh" }}>{list}</Box>
        </Container>
      </div>
    </>
  );
}

export default Dashboard;
