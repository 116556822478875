import React from "react";
import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

// Firebase Auth
import { auth, microsoftProvider } from "../../config/fbConfig";
import { signInWithPopup, OAuthProvider } from "firebase/auth";

import MsBtn from "./ms-signin_light.svg";

//React Context
import { AppContext } from "../../App";

function GeshdoSignIn() {
  const { authError, setAuthError } = React.useContext(AppContext);

  let history = useHistory();

  const ssoSignIn = async () => {
    await signInWithPopup(auth, microsoftProvider)
      .then((result) => {
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.

        // Get the OAuth access token and ID Token
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;

        history.push("/");
      })
      .catch((error) => {
        // Handle error.
        console.log(error);
        setAuthError(!authError);
      });
  };

  return (
    <>
      <h2 className="SignIn">Sign In</h2>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 3, width: "20rem" },
        }}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={2} direction="row" padding={2}>
          <Button onClick={ssoSignIn}>
            <img src={MsBtn} alt="Ms Auth Btn" />
          </Button>
        </Stack>
        <div></div>
      </Box>
    </>
  );
}

export default GeshdoSignIn;
