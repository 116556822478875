import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";

const SignedOutLinks = () => {
  return (
    <ul>
      <li>
        {/* <NavLink to="/signin" className="NavLink">
          <Button color="inherit">Login</Button>
        </NavLink> */}
      </li>
    </ul>
  );
};

export default SignedOutLinks;
