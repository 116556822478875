import { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import SelectAssetType from "./SelectAssetType";

//Firebase & Firestore
import { collection, addDoc } from "firebase/firestore";
import { useFirestore } from "reactfire";
// UUID
import { v4 as uuidv4 } from "uuid";

//React Context
import { AppContext } from "../../../App";

function CreateAsset() {
  const { isLoggedIn } = useContext(AppContext);

  const [purchaseDate, setPurchaceDate] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [model, setModel] = useState("");
  const [serialnr, setSerialNr] = useState("");
  const [lentTo, setLentTo] = useState("");
  const [lentDate, setLentDate] = useState("");
  const [operatingSystem, setOperatingSystem] = useState("");
  const [warrantyDate, setWarrantyDate] = useState("");
  const [cpu, setCpu] = useState("");
  const [ram, setRam] = useState("");
  const [hdd, setHdd] = useState("");
  const [comment, setComment] = useState("");
  const [createType, setCreateType] = useState("Computer");

  const firestore = useFirestore();
  const assetCollection = collection(firestore, "computers");

  const handlePurchaseDateChange = (e) => {
    setPurchaceDate(e.target.value);
  };

  const handleManufacturerChange = (e) => {
    setManufacturer(e.target.value);
  };

  const handleModelChange = (e) => {
    setModel(e.target.value);
  };

  const handleSerialNumberChange = (e) => {
    setSerialNr(e.target.value);
  };

  const handleLentToChange = (e) => {
    setLentTo(e.target.value);
  };

  const handleLentDateChange = (e) => {
    setLentDate(e.target.value);
  };

  const handleOperatingSystemChange = (e) => {
    setOperatingSystem(e.target.value);
  };

  const handleWarrantyDateChange = (e) => {
    setWarrantyDate(e.target.value);
  };

  const handleCpuChange = (e) => {
    setCpu(e.target.value);
  };

  const handleRamChange = (e) => {
    setRam(e.target.value);
  };

  const handleHddChange = (e) => {
    setHdd(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleAssetTypeChange = (e) => {
    if (e.target.value === "Mobile") {
      setCreateType("Mobile");
    }
    if (e.target.value === "Equipment") {
      setCreateType("Equipment");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await addDoc(assetCollection, {
      purchaseDate,
      manufacturer,
      model,
      serialnr,
      lentTo,
      lentDate,
      operatingSystem,
      warrantyDate,
      cpu,
      ram,
      hdd,
      comment,
      id: uuidv4(),
    });
    setPurchaceDate("");
    setManufacturer("");
    setModel("");
    setSerialNr("");
    setLentTo("");
    setLentDate("");
    setOperatingSystem("");
    setWarrantyDate("");
    setCpu("");
    setRam("");
    setHdd("");
    setComment("");
  };

  if (!isLoggedIn) return <Redirect to="/signin" />;
  if (createType === "Mobile") return <Redirect to="/createMobile" />;
  if (createType === "Equipment") return <Redirect to="/createEquipment" />;

  return (
    <>
      <div className="AssetHeader">
        <h3 className="CreateLink">Create New Computer Asset</h3>
      </div>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 3, width: "20rem" },
        }}
        noValidate
        autoComplete="off"
      >
        <SelectAssetType
          createType={createType}
          handleAssetTypeChange={handleAssetTypeChange}
        />

        <div>
          <TextField
            id="date"
            label="Date of purchase"
            type="date"
            defaultValue={purchaseDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handlePurchaseDateChange}
          />
        </div>
        <div>
          <TextField
            id="outlined-required"
            label="Manufacturer"
            value={manufacturer}
            onChange={handleManufacturerChange}
            variant="filled"
          />
          <TextField
            id="outlined-required"
            label="Model"
            value={model}
            onChange={handleModelChange}
            variant="filled"
          />
          <TextField
            id="outlined-required"
            label="Serial number"
            value={serialnr}
            onChange={handleSerialNumberChange}
            variant="filled"
          />
        </div>
        <div>
          <TextField
            id="outlined-required"
            label="Lent To"
            value={lentTo}
            onChange={handleLentToChange}
            variant="filled"
          />
          <TextField
            id="outlined-required"
            label="Lent date"
            type="date"
            defaultValue={lentDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleLentDateChange}
          />
        </div>
        <div>
          <TextField
            id="outlined-required"
            label="Operating System"
            value={operatingSystem}
            onChange={handleOperatingSystemChange}
            variant="filled"
          />
          <TextField
            id="outlined-required"
            label="Warranty date"
            type="date"
            defaultValue={warrantyDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleWarrantyDateChange}
          />
        </div>
        <div>
          <TextField
            id="outlined-required"
            label="CPU"
            value={cpu}
            onChange={handleCpuChange}
            variant="filled"
          />
          <TextField
            id="outlined-required"
            label="RAM"
            value={ram}
            onChange={handleRamChange}
            variant="filled"
          />
          <TextField
            id="outlined-required"
            label="HDD"
            value={hdd}
            onChange={handleHddChange}
            variant="filled"
          />
        </div>
        <div>
          <TextField
            id="outlined-multiline-static"
            label="Comments"
            multiline
            rows={4}
            value={comment}
            onChange={handleCommentChange}
            variant="filled"
          />
        </div>
        <Stack spacing={2} direction="row" padding={3}>
          <Button variant="contained" onClick={handleSubmit}>
            Create
          </Button>
        </Stack>
        <div></div>
      </Box>
    </>
  );
}

export default CreateAsset;
