import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { Link, NavLink } from "react-router-dom";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";

//React Context
import { AppContext } from "../../App";

const Navbar = () => {
  const { isLoggedIn } = useContext(AppContext);

  const links = isLoggedIn ? <SignedInLinks /> : <SignedOutLinks />;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/" className="mainLink">
              GESHDO ASSETS
            </Link>
          </Typography>
          <Typography variant="h7" component="div" sx={{ mx: 5 }}>
            <NavLink
              exact
              activeClassName="activeMainLink"
              to="/"
              className="mainLink"
            >
              COMPUTERS
            </NavLink>
          </Typography>
          <Typography variant="h7" component="div" sx={{ mx: 5 }}>
            <NavLink
              activeClassName="activeMainLink"
              to="/mobileList"
              className="mainLink"
            >
              MOBILES
            </NavLink>
          </Typography>
          <Typography variant="h7" component="div" sx={{ flexGrow: 1, mx: 5 }}>
            <NavLink
              activeClassName="activeMainLink"
              to="/equipmentList"
              className="mainLink"
            >
              EQUIPMENT
            </NavLink>
          </Typography>
          {links}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
